<template>
   <footer class="bg-black" aria-labelledby="footer-heading">
      <h2 id="footer-heading" class="sr-only">Footer</h2>
      <div class="flex flex-col mx-auto max-w-[95rem] px-6 pb-8 lg:px-8 pt-12 space-y-16">
         <div class="grid grid-cols-2 sm:grid-cols-5 gap-4">
            <div v-for="(footerMenu, index) in footerMenus" :key="index">
               <h3 class="text-2xl sm:text-3xl font-bold leading-6 text-news-category-blue">{{ footerMenu.localized_name }}</h3>
               <div role="list" class="grid mt-6 space-y-4">
                  <MenuNavigationLink
                     v-for="(child, childIndex) in footerMenu.menu_items" :key="childIndex"
                     default-class="text-md text-white hover-animation hover:text-news-category-blue"
                     active-class="text-white"
                     :item="child"/>
               </div>
            </div>
         </div>
         <div class="grid grid-cols-3 sm:flex sm:space-x-4">

            <MenuNavigationLink
               v-for="(menuItem, index) in footerLinksMenu?.menu_items ?? []" :key="index"
               default-class="text-md text-white hover-animation hover:text-news-category-blue"
               active-class="text-news-category-blue"
               :item="menuItem"/>

         </div>
         <div class="pt-8 lg:mt-22 lg:mb-22">
            <hr class="my-2 h-0.5 border-t-0 bg-white">
            <img class="h-auto w-auto" src="/assets/images/sigmalive_logo_navigation.webp" alt="SigmaLive logo">
            <hr class="my-2 h-0.5 border-t-0 bg-white">
           <div class="text-center text-white mt-4 space-y-2">
             <a href="https://admin22.com/" class="hover-animation hover:text-gray-200" target="_blank" rel="noopener noreferrer">
               <span class="text-center text-sm">Developed by P.C Admin 22 Limited</span>
             </a>
             <p class="text-center text-sm">&copy; {{ date.getFullYear() }} Powered By SigmaLive, Part of Dias Group of Companies.</p>
           </div>
         </div>
      </div>
   </footer>

</template>

<script setup lang="ts">

   import MenuNavigationLink from '~/components/Layout/Navigation/MenuNavigationLink.vue';

   const date = new Date();
   defineProps({
      footerMenus: {
         type: Array,
         required: true,
      },
      footerLinksMenu: {
         type: Object,
         required: true,
      },
   });

</script>


<style scoped>

</style>